// =======================
// get instance we need
// =======================
$ = jQuery = require('jquery');
Promise = require('promise');
window.Tether = require('tether');
var bootstrap = require('bootstrap-sass');
var bootstrapSwitch = require('bootstrap-switch');
var domready = require('domready');
var noty = require('noty');
$.noty.defaults.layout = 'topRight';
$.noty.defaults.timeout = 3000;
var page = require('page');
var error = require('./error');

// =======================
// css
// =======================
var cssify = require('cssify');
var styleNode = require('../../public/css/style.css');

// =======================
// library
// =======================
var config = (function (props) {
                        var self = {};
                        var _getByPropPath = function (o, s) {
                            // http://stackoverflow.com/a/6491621
                            s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
                            s = s.replace(/^\./, '');           // strip a leading dot
                            var a = s.split('.');
                            for (var i = 0, n = a.length; i < n; ++i) {
                                var k = a[i];
                                if (k in o) {
                                    o = o[k];
                                } else {
                                    return;
                                }
                            }
                            return o;
                        };

                        self.get = function (a) {
                            return _getByPropPath(props, a);
                        };

                        return self;
                    })({"Client":{"fadeInterval":500,"dataTables":{"fixedHeader":{"header":true,"footer":false},"ordering":true,"paging":true,"processing":true,"scrollX":true,"scrollY":true,"language":{"paginate":{"first":"先頭へ","previous":"前へ","next":"次へ","last":"最後へ"},"aria":{"paginate":{"first":"先頭へ","previous":"前へ","next":"次へ","last":"最後へ"}},"decimal":",","thousands":",","emptyTable":"対象データなし","lengthMenu":"<select><option value='10'>10件</option><option value='50'>50件</option><option value='100'>100件</option><option value='200'>200件</option><option value='-1'>全て</option></select>表示","loadingRecords":"Now Loading...","processing":"Now Processing...","search":"全文検索：","searchPlaceholder":"検索ワード","zeroRecords":"対象データなし","info":"_PAGE_ / _PAGES_ ページを表示中","infoEmpty":"対象データなし","infoFiltered":"(全_MAX_件からフィルタリング中)"}}}});;
var error = require('./error');
var i18n = require('./i18n');
var router = require('./router');

// =======================
// Controller
// =======================
var navbar = require('./lib/navbar');

// =======================
// jQuery plugins
// =======================
var datatable = require('datatables.net')(window, $);
$.extend(true, $.fn.dataTable.defaults, config.get('Client.dataTables'));

//=======================
// CSRF setting and XHR cache baster
//=======================
var token = $("meta[name='_csrf']").attr("content");
var header = $("meta[name='_csrf_header']").attr("content");
$(document).ajaxSend(function(e, xhr, options) {
  xhr.setRequestHeader(header, token);
  xhr.setRequestHeader('Pragma', 'no-cache');
  xhr.setRequestHeader('Cache-Control', 'no-cache');
  xhr.setRequestHeader('If-Modified-Since', 'Thu, 01 Jun 1970 00:00:00 GMT');
});

// =======================
// jQuery setting
// =======================
$.ajaxSetup({
  error: function(xhr) {
    if (xhr.status == 401) {
      throw new error.UnAuthorizedException();
    }
  }
})

// =======================
// global error catch handler
// =======================
window.onerror = function(msg, file, line, column, err){
  switch(err.name) {
    case 'unauthorizederror':
      location.href = '/login';
      break;
    default:
      error.show(err);
      break;
  }

}

// =======================
// entry point
// =======================
domready(function() {
  $("body").fadeIn(config.get('Client.fadeInterval'), function() {

    Promise.resolve()
      .then(i18n.init)
      .then(router.init)
      .then(navbar.init)
    ;

  });
})
