var $ = require('jquery');
var config = (function (props) {
                        var self = {};
                        var _getByPropPath = function (o, s) {
                            // http://stackoverflow.com/a/6491621
                            s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
                            s = s.replace(/^\./, '');           // strip a leading dot
                            var a = s.split('.');
                            for (var i = 0, n = a.length; i < n; ++i) {
                                var k = a[i];
                                if (k in o) {
                                    o = o[k];
                                } else {
                                    return;
                                }
                            }
                            return o;
                        };

                        self.get = function (a) {
                            return _getByPropPath(props, a);
                        };

                        return self;
                    })({"Client":{"fadeInterval":500,"dataTables":{"fixedHeader":{"header":true,"footer":false},"ordering":true,"paging":true,"processing":true,"scrollX":true,"scrollY":true,"language":{"paginate":{"first":"先頭へ","previous":"前へ","next":"次へ","last":"最後へ"},"aria":{"paginate":{"first":"先頭へ","previous":"前へ","next":"次へ","last":"最後へ"}},"decimal":",","thousands":",","emptyTable":"対象データなし","lengthMenu":"<select><option value='10'>10件</option><option value='50'>50件</option><option value='100'>100件</option><option value='200'>200件</option><option value='-1'>全て</option></select>表示","loadingRecords":"Now Loading...","processing":"Now Processing...","search":"全文検索：","searchPlaceholder":"検索ワード","zeroRecords":"対象データなし","info":"_PAGE_ / _PAGES_ ページを表示中","infoEmpty":"対象データなし","infoFiltered":"(全_MAX_件からフィルタリング中)"}}}});;
var error = require('../error');
var notice = require('../notice');
var confirm = require('../confirm');
var i18n = require('../i18n');
var $dom = $('#template-changePassword-modal');

module.exports = {
  init: init,
  destroy: destroy
}

function init() {
  return new Promise(function(resolve, reject) {
    Promise.resolve()
      .then(eventBind)
      .then(resolve)
      .catch(reject)
    ;
  });
}

function destroy() {
  return new Promise(function(resolve, reject) {
    Promise.resolve()
      .then(eventUnbind)
      .then(resolve);
  });
}

function eventBind() {
  return new Promise(function(resolve, reject) {
    $('body').on('click', '.btn-changePassword', function(e) {
      Promise.resolve()
        .then(initModal)
        .then(showModal)
      ;
    });

    $dom.on('show.bs.modal', function(e) {
    });

    $dom.on('hidden.bs.modal', function(e) {
    });

    $dom.on('click', '.btn-changePassword-post', function(e) {
      var data = $dom.find('.template-changePassword-form').serialize();
      Promise.resolve(data)
        .then(postData)
        .then(function() {
          return notice.success(i18n.get('dsp.success'));
        })
        .then(hideModal)
        .catch(showModalMessage)
      ;
    });

    resolve();
  });
}

function eventUnBind() {
  return new Promise(function(resolve, reject) {
    $('body').off('click', '.btn-changePassword');
    $dom.off('show.bs.modal');
    $dom.off('hidden.bs.modal');
    $dom.off('click', '.btn-changePassword-post');
    resolve();
  });
}

function postData(data) {
  return new Promise(function(resolve, reject) {
    $.ajax({
      type: 'put',
      url: '/changePassword',
      data: data,
      success: resolve,
      error: function(xhr) {
        if (xhr.status == 400) {
          reject(new error.AjaxValidateException(xhr));
        }
        if (xhr.status == 403) {
          throw new error.ForbiddenException();
        }
        reject(new error.AjaxException(xhr));
      }
    });
  });
}

function showModal(param) {
  return new Promise(function(resolve, reject) {
    var $modal = $dom;
    $modal.modal({});
    resolve(param);
  });
}

function initModal(param) {
  return new Promise(function(resolve, reject) {
    var $modal = $dom
    $modal.find('input').val('');
    $modal.find('.alert').remove();
    resolve(param);
  });
}

function hideModal(param) {
  return new Promise(function(resolve, reject) {
    var $modal = $dom;
    $modal.modal('hide');
    resolve(param);
  });
}

function showModalMessage(err) {
  return new Promise(function(resolve, reject) {
    var $modal = $dom
    $modal.find('.alert').remove();

    var $alert = $('<div></div>');
    $alert
      .addClass('alert alert-warning')
      .attr('client', 'alert')
      .text(err.message)
    ;

    $modal.find('.modal-header').prepend($alert);

    resolve();
  });
}
