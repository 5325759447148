var $ = require('jquery');
var config = (function (props) {
                        var self = {};
                        var _getByPropPath = function (o, s) {
                            // http://stackoverflow.com/a/6491621
                            s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
                            s = s.replace(/^\./, '');           // strip a leading dot
                            var a = s.split('.');
                            for (var i = 0, n = a.length; i < n; ++i) {
                                var k = a[i];
                                if (k in o) {
                                    o = o[k];
                                } else {
                                    return;
                                }
                            }
                            return o;
                        };

                        self.get = function (a) {
                            return _getByPropPath(props, a);
                        };

                        return self;
                    })({"Client":{"fadeInterval":500,"dataTables":{"fixedHeader":{"header":true,"footer":false},"ordering":true,"paging":true,"processing":true,"scrollX":true,"scrollY":true,"language":{"paginate":{"first":"先頭へ","previous":"前へ","next":"次へ","last":"最後へ"},"aria":{"paginate":{"first":"先頭へ","previous":"前へ","next":"次へ","last":"最後へ"}},"decimal":",","thousands":",","emptyTable":"対象データなし","lengthMenu":"<select><option value='10'>10件</option><option value='50'>50件</option><option value='100'>100件</option><option value='200'>200件</option><option value='-1'>全て</option></select>表示","loadingRecords":"Now Loading...","processing":"Now Processing...","search":"全文検索：","searchPlaceholder":"検索ワード","zeroRecords":"対象データなし","info":"_PAGE_ / _PAGES_ ページを表示中","infoEmpty":"対象データなし","infoFiltered":"(全_MAX_件からフィルタリング中)"}}}});;
var i18n = require('../i18n');
var error = require('../error');
var notice = require('../notice');
var validator = require('validator');
var Dropzone = require('dropzone');

var $dom = $('#template-profile');
var dropzone;

module.exports = {
  show: show,
  hide: hide
}

function show() {
  return new Promise(function(resolve, reject) {
    Promise.resolve({})
      .then(eventBind)
      .then(getProfile)
      .then(setForm)
      .then(function(param) {
        $dom.fadeIn(config.get('Client.fadeInterval')).promise().done(resolve);
      })
      .catch(reject)
    ;
  });
}

function hide() {
  return new Promise(function(resolve, reject) {
    Promise.resolve()
      .then(eventUnBind)
      .then(function() {
        $dom.fadeOut(config.get('Client.fadeInterval')).promise().done(resolve);
      })
      .catch(reject)
    ;
  });
}

function eventBind(param) {
  return new Promise(function(resolve, reject) {

    Dropzone.options.avatar = {
      paramName: 'avatar',
      previewsContainer: $dom.find('div#preview')[0],
      acceptedFiles: 'image/*',
      dictInvalidFileType: i18n.get('validate.invalid.imageFile'),
      parallelUploads: 1,
      thumbnailWidth: 120,
      thumbnailHeight: 120,
      complete: function() {
        $('.dz-success-mark, .dz-error-mark').hide();
      },
      success: function(file, responseText, e) {
        $dom.find('#avatar').remove();
        notice.success(responseText.message);
      },
      error: function(file, message) {
        $('.dz-preview').hide();
        notice.error(message);
      }
    }

    $dom.find('#preview').empty()
      .append(
        $('<div></div>')
          .attr('id', 'avatar')
          .addClass('text-bordering white')
          .text('Click Upload')
      )
    ;

    dropzone = new Dropzone($dom.find('div#avatar')[0], {
      url: '/local/api/profile/upload'
    });

    $dom.on('click', '.btn-profile-put', function(e) {
      var param = {
        data: $dom.find('.template-profile-form').serialize()
      }
      Promise.resolve(param)
        .then(putProfile)
        .then(function() {
          notice.success(i18n.get('dsp.success'));
          return param;
        })
        .then(getProfile)
        .then(setForm)
        .catch(function(err) {
          notice.warning(err.message)
        })
      ;
    });

    resolve(param);
  });
}

function eventUnBind() {
  return new Promise(function(resolve, reject) {
    dropzone.destroy();
    $dom.off('click', '.btn-profile-put');
    resolve();
  });
}

function getProfile(param) {
  return new Promise(function(resolve, reject) {
    $.ajax({
      type: 'get',
      url: '/local/api/profile',
      success: function(json) {
        param.profile = json;
        resolve(param);
      },
      error: function(xhr) {
        if (xhr.status == 401) {
          throw new error.UnAuthorizedException();
        }
        reject(new error.AjaxException(xhr));
      }
    });
  });
}

function setForm(param) {
  return new Promise(function(resolve, reject) {
    var $form = $dom.find('.template-profile-form');

    if (param && param.profile) {
      $dom.find('#avatar').css('background-image', 'url(' + param.profile.image + ')' );
      $form.find('[name=_id]').val(param.profile._id);
      $form.find('[name=fullName]').val(param.profile.fullName);
      $form.find('[name=email]').val(param.profile.email);
      $form.find('[name=phone]').val(param.profile.phone);
    }

    resolve(param);
  });
}

function putProfile(param) {
  return new Promise(function(resolve, reject) {
    $.ajax({
      type: 'put',
      url: '/local/api/profile',
      data: param.data,
      success: function() {
        resolve(param);
      },
      error: function(xhr) {
        if (xhr.status == 401) {
          throw new error.UnAuthorizedException();
        }
        if (xhr.status == 403) {
          throw new error.ForbiddenException();
        }
        if (xhr.status == 400) {
          reject(new error.AjaxValidateException(xhr));
        }
        reject(new error.AjaxException(xhr));
      }
    });
  });
}

