var $ = require('jquery');

/**
 * make CSV and download on clientside script
 * array = [["column1", "column2", ...], [cell1, cell2, ...], [cell1, cell2, ...]]
**/
exports.download = function(array, name) {

  var csv_string = arrayToCsv(array);

  // make file
  var blob = new Blob([csv_string], {
    type: "text/csv;charset=utf-8;"
  });

  // download
  if (window.navigator.msSaveOrOpenBlob) {
    // IE
    return navigator.msSaveBlob(blob, name);
  }

  // except IE
  var link = $('<a></a>');
  link.attr('href', window.URL.createObjectURL(blob));
  link.attr('download', name);
  link.attr('target', '_blank');
  $('body').append(link);
  link[0].click();
  link.remove();

  return;
};

function arrayToCsv(array) {
  var str = "";
  for (var i=0; i < array.length; i++) {
      str += array[i].join(",");
      str += '\r\n';
  }
  // BOM
  return "\ufeff" + str;
}
