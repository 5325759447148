var $ = require('jquery');
var config = (function (props) {
                        var self = {};
                        var _getByPropPath = function (o, s) {
                            // http://stackoverflow.com/a/6491621
                            s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
                            s = s.replace(/^\./, '');           // strip a leading dot
                            var a = s.split('.');
                            for (var i = 0, n = a.length; i < n; ++i) {
                                var k = a[i];
                                if (k in o) {
                                    o = o[k];
                                } else {
                                    return;
                                }
                            }
                            return o;
                        };

                        self.get = function (a) {
                            return _getByPropPath(props, a);
                        };

                        return self;
                    })({"Client":{"fadeInterval":500,"dataTables":{"fixedHeader":{"header":true,"footer":false},"ordering":true,"paging":true,"processing":true,"scrollX":true,"scrollY":true,"language":{"paginate":{"first":"先頭へ","previous":"前へ","next":"次へ","last":"最後へ"},"aria":{"paginate":{"first":"先頭へ","previous":"前へ","next":"次へ","last":"最後へ"}},"decimal":",","thousands":",","emptyTable":"対象データなし","lengthMenu":"<select><option value='10'>10件</option><option value='50'>50件</option><option value='100'>100件</option><option value='200'>200件</option><option value='-1'>全て</option></select>表示","loadingRecords":"Now Loading...","processing":"Now Processing...","search":"全文検索：","searchPlaceholder":"検索ワード","zeroRecords":"対象データなし","info":"_PAGE_ / _PAGES_ ページを表示中","infoEmpty":"対象データなし","infoFiltered":"(全_MAX_件からフィルタリング中)"}}}});;
var error = require('../error');
var notice = require('../notice');
var confirm = require('../confirm');
var i18n = require('../i18n');
var $dom = $('#template-client');

module.exports = {
  show: show,
  hide: hide
}

function show() {
  return new Promise(function(resolve, reject) {
    Promise.resolve()
      .then(setTable)
      .then(refreshList)
      .then(eventBind)
      .then(function() {
        $dom.fadeIn(config.get('Client.fadeInterval')).promise().done(resolve);
      })
      .catch(reject)
    ;
  });
}

function hide() {
  return new Promise(function(resolve, reject) {
    Promise.resolve()
      .then(eventUnBind)
      .then(function() {
        $dom.fadeOut(config.get('Client.fadeInterval')).promise().done(resolve);
      })
      .catch(reject)
    ;
  });
}

function eventBind() {
  return new Promise(function(resolve, reject) {
    $dom.on('click', '.btn-client-add', function(e) {
      Promise.resolve()
        .then(initModal)
        .then(showModal)
      ;
    });

    $dom.find('#template-client-modal').on('show.bs.modal', function(e) {
    });

    $dom.find('#template-client-modal').on('hidden.bs.modal', function(e) {
    });

    $dom.on('click', '.btn-client-post', function(e) {
      var data = $dom.find('.template-client-form').serialize();
      Promise.resolve(data)
        .then(postData)
        .then(function() {
          return notice.success(i18n.get('dsp.success'));
        })
        .then(hideModal)
        .then(refreshList)
        .catch(showModalMessage)
      ;
    });

    $dom.on('click', '.btn-client-put', function(e) {

      // release disabled once for serialize()
      $dom.find('.template-client-form').find('[name=id]').prop('disabled', false);
      var data = $dom.find('.template-client-form').serialize();
      $dom.find('.template-client-form').find('[name=id]').prop('disabled', true);

      Promise.resolve(data)
        .then(putData)
        .then(function() {
          return notice.success(i18n.get('dsp.success'));
        })
        .then(hideModal)
        .then(refreshList)
        .catch(showModalMessage)
      ;
    });


    $dom.on('click', '.btn-edit', function(e) {
      var data = JSON.parse($(e.target).closest('tr').attr('data-json'));
      Promise.resolve({data: data})
        .then(initModal)
        .then(showModal)
      ;
    });

    $dom.on('click', '.btn-delete', function(e) {
      var data = JSON.parse($(e.target).closest('tr').attr('data-json'));
      Promise.resolve({data: data})
        .then(confirm.confirm)
        .then(function(data) {
          Promise.resolve(data)
            .then(deleteData)
            .then(refreshList)
            .catch(error.show)
          ;
        })
        .catch(function() {
          // cancel
        });
    });

    resolve();
  });
}

function eventUnBind() {
  return new Promise(function(resolve, reject) {
    $dom.off('click', '.btn-client-add');
    $dom.find('#template-client-modal').off('show.bs.modal');
    $dom.find('#template-client-modal').off('hidden.bs.modal');
    $dom.off('click', '.btn-client-post');
    $dom.off('click', '.btn-client-put');
    $dom.off('click', '.btn-edit');
    $dom.off('click', '.btn-delete');
    resolve();
  });
}

function setTable() {
  return new Promise(function(resolve, reject) {

    var table = $('<table></table>');

    table
      .addClass('table table-striped table-bordered table-hover')
      .append(
        $('<thead></thead>')
          .append(
            $('<tr></tr>')
              .append($('<th></th>')
                .text(i18n.get('dsp.control.client.ownerUserName'))
                .css('min-width', '150px')
              )
              .append($('<th></th>')
                .text(i18n.get('dsp.control.client.name'))
                .css('min-width', '200px')
              )
              .append($('<th></th>').text(i18n.get('dsp.control.client.clientId')))
              .append($('<th></th>').text(i18n.get('dsp.control.client.cliantSecret')))
              .append($('<th></th>')
                .text(i18n.get('dsp.control.client.domain'))
                .css('min-width', '200px')
              )
              .append($('<th></th>')
                .text(i18n.get('dsp.control.action'))
                .css('min-width', '170px')
              )
          )
      )
      .append(
        $('<tbody></tbody>')
      )
    ;

    $dom.find('.clientlist')
      .empty()
      .append(table);

    resolve();
  });

}

function setTableRow(json) {
  return new Promise(function(resolve, reject) {
    var $tbody = $dom.find('.clientlist').find('tbody');

    $tbody
      .empty();

    $.each(json, function(index, client) {
      var row = $('<tr></tr>');

      row
        .attr('data-json', JSON.stringify(client))
        .append($('<td></td>').text(client.user.username))
        .append($('<td></td>').text(client.name))
        .append($('<td></td>').text(client.id))
        .append($('<td></td>').text(client.secret))
        .append($('<td></td>').text(client.domain))
        .append($('<td></td>').append([
          $('<button></button>')
            .addClass('btn btn-primary btn-edit')
            .text(i18n.get('dsp.control.button.edit')),
          $('<span></span>').html('&nbsp'),
          $('<button></button>')
            .addClass('btn btn-primary btn-delete')
            .text(i18n.get('dsp.control.button.delete'))
        ]))
      ;

      $tbody
        .append(row);

    });

    resolve();

  });
}

function getData() {
  return new Promise(function(resolve, reject) {
    $.ajax({
      type: 'get',
      url: '/local/api/clients',
      success: resolve,
      error: function(xhr) {
        if (xhr.status == 401) {
          throw new error.UnAuthorizedException();
        }
        reject(new error.AjaxException(xhr));
      }
    });
  });
}

function postData(data) {
  return new Promise(function(resolve, reject) {
    $.ajax({
      type: 'post',
      url: '/local/api/clients',
      data: data,
      success: resolve,
      error: function(xhr) {
        if (xhr.status == 401) {
          throw new error.UnAuthorizedException();
        }
        if (xhr.status == 403) {
          throw new error.ForbiddenException();
        }
        if (xhr.status == 400) {
          reject(new error.AjaxValidateException(xhr));
        }
        reject(new error.AjaxException(xhr));
      }
    });
  });
}

function putData(data) {
  return new Promise(function(resolve, reject) {
    $.ajax({
      type: 'put',
      url: '/local/api/clients',
      data: data,
      success: resolve,
      error: function(xhr) {
        if (xhr.status == 401) {
          throw new error.UnAuthorizedException();
        }
        if (xhr.status == 403) {
          throw new error.ForbiddenException();
        }
        if (xhr.status == 400) {
          reject(new error.AjaxValidateException(xhr));
        }
        reject(new error.AjaxException(xhr));
      }
    });
  });
}

function deleteData(param) {
  return new Promise(function(resolve, reject) {
    $.ajax({
      type: 'delete',
      url: '/local/api/clients',
      data: param.data,
      success: resolve,
      error: function(xhr) {
        if (xhr.status == 401) {
          throw new error.UnAuthorizedException();
        }
        if (xhr.status == 403) {
          throw new error.ForbiddenException();
        }
        if (xhr.status == 400) {
          reject(new error.AjaxValidateException(xhr));
        }
        reject(new error.AjaxException(xhr));
      }
    });
  });
}


function showModal(param) {
  return new Promise(function(resolve, reject) {
    var $modal = $dom.find('#template-client-modal');
    if (param && param.data && param.data._id) {
      // put mode
      $modal.find('.btn-client-put').show();
      $modal.find('.btn-client-post').hide();
      $modal.find('[name=_id]').val(param.data._id);
      $modal.find('[name=id]').prop('disabled', true).val(param.data.id);
      $modal.find('[name=domain]').val(param.data.domain);
      $modal.find('[name=name]').val(param.data.name);

    } else {
      // post mode
      $modal.find('.btn-client-put').hide();
      $modal.find('.btn-client-post').show();
      $modal.find('[name=id]').prop('disabled', false);
    }

    $modal.modal({});
    resolve(param);
  });
}

function initModal(param) {
  return new Promise(function(resolve, reject) {
    var $modal = $dom.find('#template-client-modal');
    $modal.find('input').val('');
    $modal.find('.alert').remove();

    resolve(param);
  });
}

function hideModal(param) {
  return new Promise(function(resolve, reject) {
    var $modal = $dom.find('#template-client-modal');
    $modal.modal('hide');
    resolve(param);
  });
}

function showModalMessage(err) {
  return new Promise(function(resolve, reject) {
    var $modal = $dom.find('#template-client-modal');
    $modal.find('.alert').remove();

    var $alert = $('<div></div>');
    $alert
      .addClass('alert alert-warning')
      .attr('client', 'alert')
      .text(err.message)
    ;

    $modal.find('.modal-header').prepend($alert);

    resolve();
  });
}


function refreshList() {
  return new Promise(function(resolve, reject) {
    Promise.resolve()
      .then(getData)
      .then(setTableRow)
      .then(resolve)
      .catch(reject)
    ;
  });
}


