var $ = require('jquery');
var config = (function (props) {
                        var self = {};
                        var _getByPropPath = function (o, s) {
                            // http://stackoverflow.com/a/6491621
                            s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
                            s = s.replace(/^\./, '');           // strip a leading dot
                            var a = s.split('.');
                            for (var i = 0, n = a.length; i < n; ++i) {
                                var k = a[i];
                                if (k in o) {
                                    o = o[k];
                                } else {
                                    return;
                                }
                            }
                            return o;
                        };

                        self.get = function (a) {
                            return _getByPropPath(props, a);
                        };

                        return self;
                    })({"Client":{"fadeInterval":500,"dataTables":{"fixedHeader":{"header":true,"footer":false},"ordering":true,"paging":true,"processing":true,"scrollX":true,"scrollY":true,"language":{"paginate":{"first":"先頭へ","previous":"前へ","next":"次へ","last":"最後へ"},"aria":{"paginate":{"first":"先頭へ","previous":"前へ","next":"次へ","last":"最後へ"}},"decimal":",","thousands":",","emptyTable":"対象データなし","lengthMenu":"<select><option value='10'>10件</option><option value='50'>50件</option><option value='100'>100件</option><option value='200'>200件</option><option value='-1'>全て</option></select>表示","loadingRecords":"Now Loading...","processing":"Now Processing...","search":"全文検索：","searchPlaceholder":"検索ワード","zeroRecords":"対象データなし","info":"_PAGE_ / _PAGES_ ページを表示中","infoEmpty":"対象データなし","infoFiltered":"(全_MAX_件からフィルタリング中)"}}}});;
var error = require('../error');
var user = require("./user");
var role = require("./role");
var client = require("./client");
var setting = require("./setting");
var page = require('page');

var $dom = $('#template-menu');

module.exports = {
  show: show,
  hide: hide
}

function show() {
  return new Promise(function(resolve, reject) {
    Promise.resolve({})
      .then(getSetting)
      .then(eventBind)
      .then(function() {
        $dom.fadeIn(config.get('Client.fadeInterval'), resolve);
      })
      .catch(reject)
  });
}

function hide() {
  return new Promise(function(resolve, reject) {
    Promise.resolve()
      .then(eventUnBind)
      .then(function() {
        $dom.fadeOut(config.get('Client.fadeInterval'), resolve);
      })
      .catch(reject)
    ;
  });
}

function eventBind() {
  return new Promise(function(resolve, reject) {
    $dom.on('click', '.btn-start', onClickStart);
    resolve();
  });
}

function eventUnBind() {
  return new Promise(function(resolve, reject) {
    $dom.off('click', '.btn-start');
    resolve();
  });
}

function onClickStart(event) {
  page.redirect('/' + $(this).data('kind'));
}

function getSetting(param) {
  return new Promise(function(resolve, reject) {
    $.ajax({
      type: 'get',
      url: '/local/api/setting',
      success: function(json) {
        if (json && json instanceof Array) {
          param.setting = json[0];
        } else {
          param.setting = undefined;
        }
        resolve(param);
      },
      error: function(xhr) {
        if (xhr.status == 401) {
          throw new error.UnAuthorizedException();
        }
        reject(new error.AjaxException(xhr));
      }
    });
  });
}


