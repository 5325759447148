var $ = require('jquery');
var moment = require('moment');
var config = (function (props) {
                        var self = {};
                        var _getByPropPath = function (o, s) {
                            // http://stackoverflow.com/a/6491621
                            s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
                            s = s.replace(/^\./, '');           // strip a leading dot
                            var a = s.split('.');
                            for (var i = 0, n = a.length; i < n; ++i) {
                                var k = a[i];
                                if (k in o) {
                                    o = o[k];
                                } else {
                                    return;
                                }
                            }
                            return o;
                        };

                        self.get = function (a) {
                            return _getByPropPath(props, a);
                        };

                        return self;
                    })({"Client":{"fadeInterval":500,"dataTables":{"fixedHeader":{"header":true,"footer":false},"ordering":true,"paging":true,"processing":true,"scrollX":true,"scrollY":true,"language":{"paginate":{"first":"先頭へ","previous":"前へ","next":"次へ","last":"最後へ"},"aria":{"paginate":{"first":"先頭へ","previous":"前へ","next":"次へ","last":"最後へ"}},"decimal":",","thousands":",","emptyTable":"対象データなし","lengthMenu":"<select><option value='10'>10件</option><option value='50'>50件</option><option value='100'>100件</option><option value='200'>200件</option><option value='-1'>全て</option></select>表示","loadingRecords":"Now Loading...","processing":"Now Processing...","search":"全文検索：","searchPlaceholder":"検索ワード","zeroRecords":"対象データなし","info":"_PAGE_ / _PAGES_ ページを表示中","infoEmpty":"対象データなし","infoFiltered":"(全_MAX_件からフィルタリング中)"}}}});;
var error = require('../error');
var notice = require('../notice');
var confirm = require('../confirm');
var i18n = require('../i18n');
var bulk_user = require('./bulk-user');
var $dom = $('#template-user');

module.exports = {
  show: show,
  hide: hide
}

function show() {
  return new Promise(function(resolve, reject) {
    Promise.resolve({})
      .then(getSetting)
      .then(setUserTable)
      .then(refreshUserList)
      .then(eventBind)
      .then(function() {
        $dom.fadeIn(config.get('Client.fadeInterval')).promise().done(resolve);
      })
      .catch(reject)
    ;
  });
}

function hide() {
  return new Promise(function(resolve, reject) {
    Promise.resolve()
      .then(eventUnBind)
      .then(function() {
        $dom.fadeOut(config.get('Client.fadeInterval')).promise().done(resolve);
      })
      .catch(reject)
    ;
  });
}

function eventBind() {
  return new Promise(function(resolve, reject) {
    $dom.on('click', '.btn-user-add', function(e) {
      Promise.resolve({})
        .then(getSetting)
        .then(initModal)
        .then(styleSetup)
        .then(showModal)
      ;
    });

    $dom.on('switchChange.bootstrapSwitch', function(e, state) {
      $(e.target).val((state) ? 'on' : undefined);
    });

    $dom.find('#template-user-modal').on('show.bs.modal', function(e) {
    });

    $dom.find('#template-user-modal').on('hidden.bs.modal', function(e) {
    });

    $dom.find('#template-token-modal').on('hide.bs.modal', function(e) {
      $dom.find('[data-toggle="popover"]').popover('hide');
    });

    $dom.find('#template-user-bulk-modal').on('hidden.bs.modal', function(e) {
      Promise.resolve({})
        .then(getSetting)
        .then(setUserTable)
        .then(refreshUserList)
        .catch(reject)
      ;
    });

    $dom.find('#template-history-modal').on('show.bs.modal', function(e) {
    });

    $dom.find('#template-history-modal').on('shown.bs.modal', function(e) {
      // for adjust datatable's header
      $(window).trigger('resize');
    });

    $dom.find('#template-history-modal').on('hidden.bs.modal', function(e) {
    });

    $dom.on('click', '.btn-user-post', function(e) {
      var param = {
        data: $dom.find('.template-user-form').serialize()
      };
      Promise.resolve(param)
        .then(postUser)
        .then(function(param) {
          notice.success(i18n.get('dsp.success'));
          return param;
        })
        .then(hideModal)
        .then(refreshUserList)
        .catch(showModalMessage)
      ;
    });

    $dom.on('click', '.btn-user-put', function(e) {
      var $username_dom = $dom.find('.template-user-form').find('[name=username]');
      var username_lock = $username_dom.prop('disabled')

      if (username_lock) $username_dom.prop('disabled', false);
      var param = {
        data: $dom.find('.template-user-form').serialize()
      }
      if (username_lock) $username_dom.prop('disabled', true);

      Promise.resolve(param)
        .then(putUser)
        .then(function() {
          notice.success(i18n.get('dsp.success'));
          return param;
        })
        .then(hideModal)
        .then(refreshUserList)
        .catch(showModalMessage)
      ;
    });


    $dom.on('click', '.btn-edit', function(e) {
      var data = JSON.parse($(e.target).closest('tr').attr('data-json'));
      Promise.resolve({data: data})
        .then(getSetting)
        .then(initModal)
        .then(styleSetup)
        .then(showModal)
        .catch(error.show)
      ;
    });

    $dom.on('click', '.btn-delete', function(e) {
      var data = JSON.parse($(e.target).closest('tr').attr('data-json'));
      Promise.resolve({data: data})
        .then(confirm.confirm)
        .then(function(param) {
          Promise.resolve(param)
            .then(deleteUsers)
            .then(refreshUserList)
            .catch(error.show)
          ;
        })
        .catch(function() {
          // cancel
        });
    });

    // history button click event
    $dom.on('click', '.btn-history', function(e) {
      var data = JSON.parse($(e.target).closest('tr').attr('data-json'));
      Promise.resolve({data: data})
        .then(getHistories)
        .then(initHistoryModal)
        .then(showHistoryModal)
        .catch(error.show)
      ;
    });


    // check token button click event
    $dom.on('click', '.btn-token', function(e) {
      var data = JSON.parse($(e.target).closest('tr').attr('data-json'));
      Promise.resolve({tokens: data.tokens})
        .then(initTokenModal)
        .then(showTokenModal)
        .catch(error.show)
      ;
    });

    // bulk update button click event
    $dom.on('click', '.btn-user-bulk-add', function(e) {
      Promise.resolve({})
        .then(bulk_user.show)
        .catch(error.show)
      ;
    });

    resolve();
  });
}

function eventUnBind() {
  return new Promise(function(resolve, reject) {
    $dom.off('click', '.btn-user-add');
    $dom.find('#template-user-modal').off('show.bs.modal');
    $dom.find('#template-user-modal').off('hidden.bs.modal');
    $dom.find('#template-token-modal').off('hide.bs.modal');
    $dom.off('click', '.btn-user-post')
    $dom.off('click', '.btn-user-put');
    $dom.off('click', '.btn-edit');
    $dom.off('click', '.btn-delete');
    $dom.off('click', '.btn-token');
    $dom.off('click', '.btn-history');
    $dom.off('switchChange.bootstrapSwitch');
    $dom.off('click', '.btn-user-bulk-add');
    $dom.find('#template-user-bulk-modal').off('hidden.bs.modal');
    $dom.find('#template-history-modal').off('show.bs.modal');
    $dom.find('#template-history-modal').off('hidden.bs.modal');
    $dom.find('#template-history-modal').off('shown.bs.modal');
    resolve();
  });
}

function setUserTable(param) {
  return new Promise(function(resolve, reject) {

    var table = $('<table></table>');

    table
      .addClass('table table-striped table-bordered table-hover')
      .append(
        $('<thead></thead>')
          .append(
            $('<tr></tr>')
              .append($('<th></th>').text('id').hide())
              .append($('<th></th>')
                .text(i18n.get('dsp.control.username'))
                .css('min-width', '220px')
              )
              .append($('<th></th>')
                .text(i18n.get('dsp.control.userFullName'))
                .css('min-width', '200px')
              )
              .append($('<th></th>').text(i18n.get('dsp.control.role.title')))
              .append($('<th></th>')
                .text(i18n.get('dsp.control.action'))
                .css('min-width', '250px')
              )
          )
      )
      .append(
        $('<tbody></tbody>')
      )
    ;

    $dom.find('.userlist')
      .empty()
      .append(table);

    resolve(param);
  });

}

function setUserTableRow(param) {
  return new Promise(function(resolve, reject) {
    var $tbody = $dom.find('.userlist').find('tbody');

    $tbody
      .empty();

    $.each(param.users, function(index, user) {
      var row = $('<tr></tr>');

      var buttons = [
        $('<button></button>')
          .addClass('btn btn-primary btn-edit')
          .text(i18n.get('dsp.control.button.edit')),
        $('<span></span>').html('&nbsp'),
        $('<button></button>')
          .addClass('btn btn-primary btn-delete')
          .text(i18n.get('dsp.control.button.delete')),
        $('<span></span>').html('&nbsp'),
        $('<button></button>')
          .addClass('btn btn-primary btn-history')
          .text(i18n.get('dsp.control.button.history')),
        $('<span></span>').html('&nbsp')
      ];
      if (param.setting && param.setting.system.enableTokenView) {
        buttons.push(
          $('<button></button>')
            .addClass('btn btn-primary btn-token')
            .text(i18n.get('dsp.control.button.checkToken'))
        );
      }

      user.image = undefined;

      row
        .attr('data-json', JSON.stringify(user))
        .append($('<td></td>').text(user._id).hide())
        .append($('<td></td>')
          .append($('<img></img>').attr('src', '/profile/avatar/' + user.username).addClass('avatar'))
          .append($('<span></span>').text(user.username))
        )
        .append($('<td></td>').text(user.fullName))
        .append($('<td></td>').text(user.roles.map(function(elem){ return elem.fullName}).join(',')))
        .append($('<td></td>').append(buttons))
      ;

      $tbody
        .append(row);

    });

    resolve(param);

  });
}

function getSetting(param) {
  return new Promise(function(resolve, reject) {
    $.ajax({
      type: 'get',
      url: '/local/api/setting',
      success: function(json) {
        if (json && json instanceof Array) {
          param.setting = json[0];
        } else {
          param.setting = undefined;
        }
        resolve(param);
      },
      error: function(xhr) {
        if (xhr.status == 401) {
          throw new error.UnAuthorizedException();
        }
        reject(new error.AjaxException(xhr));
      }
    });
  });
}

function getUsers(param) {
  return new Promise(function(resolve, reject) {
    $.ajax({
      type: 'get',
      url: '/local/api/users',
      success: function(json) {
        param.users = json;
        resolve(param);
      },
      error: function(xhr) {
        if (xhr.status == 401) {
          throw new error.UnAuthorizedException();
        }
        reject(new error.AjaxException(xhr));
      }
    });
  });
}

function getRoles(param) {
  return new Promise(function(resolve, reject) {
    $.ajax({
      type: 'get',
      url: '/local/api/roles',
      success: function(json) {
        param.roles = json;
        resolve(param);
      },
      error: function(xhr) {
        if (xhr.status == 401) {
          throw new error.UnAuthorizedException();
        }
        reject(new error.AjaxException(xhr));
      }
    });
  });
}

function postUser(param) {
  return new Promise(function(resolve, reject) {
    $.ajax({
      type: 'post',
      url: '/local/api/users',
      data: param.data,
      success: function() {
        resolve(param);
      },
      error: function(xhr) {
        if (xhr.status == 401) {
          throw new error.UnAuthorizedException();
        }
        if (xhr.status == 403) {
          throw new error.ForbiddenException();
        }
        if (xhr.status == 400) {
          return reject(new error.AjaxValidateException(xhr));
        }
        reject(new error.AjaxException(xhr));
      }
    });
  });
}

function putUser(param) {
  return new Promise(function(resolve, reject) {
    $.ajax({
      type: 'put',
      url: '/local/api/users',
      data: param.data,
      success: function() {
        resolve(param);
      },
      error: function(xhr) {
        if (xhr.status == 401) {
          throw new error.UnAuthorizedException();
        }
        if (xhr.status == 403) {
          throw new error.ForbiddenException();
        }
        if (xhr.status == 400) {
          reject(new error.AjaxValidateException(xhr));
        }
        reject(new error.AjaxException(xhr));
      }
    });
  });
}

function deleteUsers(param) {
  return new Promise(function(resolve, reject) {
    $.ajax({
      type: 'delete',
      url: '/local/api/users',
      data: param.data,
      success: resolve,
      error: function(xhr) {
        if (xhr.status == 401) {
          throw new error.UnAuthorizedException();
        }
        if (xhr.status == 403) {
          throw new error.ForbiddenException();
        }
        if (xhr.status == 400) {
          reject(new error.AjaxValidateException(xhr));
        }
        reject(new error.AjaxException(xhr));
      }
    });
  });
}

function getHistories(param) {
  return new Promise(function(resolve, reject) {
    $.ajax({
      type: 'get',
      url: '/local/api/histories/' + param.data.username,
      success: function(json) {
        param.histories = json;
        resolve(param);
      },
      error: function(xhr) {
        if (xhr.status == 401) {
          throw new error.UnAuthorizedException();
        }
        reject(new error.AjaxException(xhr));
      }
    });
  });
}


function showModal(param) {
  return new Promise(function(resolve, reject) {
    var $modal = $dom.find('#template-user-modal');

    if (param && param.data && param.data.username) {
      // put mode
      $modal.find('.btn-user-put').show();
      $modal.find('.btn-user-post').hide();
      $modal.find('[name=_id]').val(param.data._id);

      if (param.setting && param.setting.system.emailLogin) {
        // username is editable when enabled emailLogin
        $modal.find('[name=username]').prop('disabled', false).val(param.data.username);
      } else {
        $modal.find('[name=username]').prop('disabled', true).val(param.data.username);
      }
      $modal.find('[name=fullName]').val(param.data.fullName);
      $modal.find('[name=email]').val(param.data.email);
      $modal.find('[name=phone]').val(param.data.phone);
      $modal.find('[name=extentionKey1]').val(param.data.extentionKey1);
      $modal.find('[name=extentionKey2]').val(param.data.extentionKey2);
      $modal.find('[name=extentionKey3]').val(param.data.extentionKey3);
      $.each($modal.find('[name=roles]').find('option'), function(index, option) {
        $.each(param.data.roles, function(index2, role) {
          if ($(option).val() == role._id) {
            $(option).attr('selected', 'selected');
          }
        });
      });
      if (param.data.requireChangePassNext) {
        $modal.find('[name="requireChangePassNext"]').bootstrapSwitch('state', true, true);
      } else {
        $modal.find('[name="requireChangePassNext"]').bootstrapSwitch('state', false, false);
      }
      if (param.data.isLock) {
        $modal.find('[name="isLock"]')
          .bootstrapSwitch('state', true, true)
          .bootstrapSwitch('disabled', false)
        ;
      } else {
        $modal.find('[name="isLock"]')
          .bootstrapSwitch('state', false, false)
          .bootstrapSwitch('disabled', true)
        ;
      }
      if (param.data.isAccountLock) {
        $modal.find('[name="isAccountLock"]')
          .bootstrapSwitch('state', true, true)
          .bootstrapSwitch('disabled', false)
        ;
      } else {
        $modal.find('[name="isAccountLock"]')
          .bootstrapSwitch('state', false, false)
          .bootstrapSwitch('disabled', false)
        ;
      }

    } else {
      // post mode
      $modal.find('.btn-user-put').hide();
      $modal.find('.btn-user-post').show();
      $modal.find('[name=username]').prop('disabled', false);
      $modal.find('[name="requireChangePassNext"]').bootstrapSwitch('state', false, false);
      $modal.find('[name="isLock"]')
        .bootstrapSwitch('state', false, false)
        .bootstrapSwitch('disabled', true)
      ;
      $modal.find('[name="isAccountLock"]')
        .bootstrapSwitch('state', false, false)
        .bootstrapSwitch('disabled', true)
      ;
    }

    $modal.modal({});
    resolve(param);
  });
}

function initModal(param) {
  return new Promise(function(resolve, reject) {
    var $modal = $dom.find('#template-user-modal');
    $modal.find('input').val('');
    $modal.find('.alert').remove();

    var $roles = $modal.find('[name=roles]');

    Promise.resolve(param)
      .then(getRoles)
      .then(function(param) {
        $roles.empty();
        $.each(param.roles, function(index, role) {
          var option = $('<option></option>');
          option
            .attr('value', role._id)
            .addClass('list-group-item')
            .text(role.fullName)
          ;
          $roles.append(option);
        });
        return param;
      })
      .then(function(param) {
        resolve(param);
      })
    ;
  });
}

function initUserBulkModal(param) {
  return new Promise(function(resolve, reject) {
    var $modal = $dom.find('#template-user-bulk-modal');
    $modal.find('.upload-zone').show();
    $modal.find('.edit-zone').hide();
    resolve(param);
  });
}

function showUserBulkModal(param) {
  return new Promise(function(resolve, reject) {
    var $modal = $dom.find('#template-user-bulk-modal');
    $modal.modal({});
    resolve(param);
  });
}

function showHistoryModal(param) {
  return new Promise(function(resolve, reject) {
    var $modal = $dom.find('#template-history-modal');
    $modal.modal({});
    resolve(param);
  });
}

function initHistoryModal(param) {
  return new Promise(function(resolve, reject) {
    var $modal = $dom.find('#template-history-modal');
    $modal.find('.modal-body').empty();

    Promise.resolve(param)
      .then(function(param) {

        $modal.find('.modal-body').append(
          $('<table></table>')
            .attr('id', 'historyTable')
        );

        var rows = (!param.histories) ? [] : param.histories.operationHistory.map(function(element, index, array) {
          element.createdAt = moment(element.createdAt).format("YYYY-MM-DD HH:mm:ss");
          return element;
        });


        var columns = [
          {title: i18n.get('dsp.control.operationHistory.createdAt'), data: 'createdAt', width: '20%'},
          {title: i18n.get('dsp.control.operationHistory.action'),    data: 'action', width: '40%'},
          {title: i18n.get('dsp.control.operationHistory.detail'),    data: 'detail', width: '40%'}
        //{title: 'json',      data: 'json'},
        ];

        $modal.find('#historyTable').DataTable({
          order: [[0, "desc"]],
          data: rows,
          scrollY: '50vh',
          columns: columns
        });

      })
      .then(function() {
        resolve(param);
      })
    ;
  });
}


function showTokenModal(param) {
  return new Promise(function(resolve, reject) {
    var $modal = $dom.find('#template-token-modal');
    $modal.modal({});
    resolve(param);
  });
}

function initTokenModal(param) {
  return new Promise(function(resolve, reject) {
    var $modal = $dom.find('#template-token-modal');
    $modal.find('.alert').remove();
    $modal.find('.modal-body').empty();

    Promise.resolve(param)
      .then(function(param) {
        var $list = $('<div></div>')
        $list.addClass('list-group');

        $.each(param.tokens, function(index, token) {
          var $head = $('<a></a>');
          $head
            .addClass('list-group-item active')
            .text('id:' + token._id)
          ;
          $list.append($head);

          var $detail = $('<a></a>');
          $detail
            .addClass('list-group-item text ellipsis');

          $detail.text('scope: ' + token.scope);
          $list.append($detail.clone());
          $detail.text('expirationDate: ' + token.expirationDate);
          $list.append($detail.clone());
          $detail
            .text('accesstoken: ' + token.accesstoken)
            .attr('tabindex', '0')
            .attr('data-toggle', 'popover')
            .attr('title', 'accesstoken')
            .attr('data-content', token.accesstoken)
          ;
          $list.append($detail.clone());
          $detail
            .text('refreshtoken: ' + token.refreshtoken)
            .attr('tabindex', '0')
            .attr('data-toggle', 'popover')
            .attr('title', 'refreshtoken')
            .attr('data-content', token.refreshtoken)
          ;
          $list.append($detail.clone());

        });

        $modal.find('.modal-body').append($list);
        $modal.find('[data-toggle="popover"]').popover();
      })
      .then(function() {
        resolve(param);
      })
    ;
  });
}

function hideModal(param) {
  return new Promise(function(resolve, reject) {
    var $modal = $dom.find('#template-user-modal');
    $modal.modal('hide');
    resolve(param);
  });
}

function showModalMessage(err) {
  return new Promise(function(resolve, reject) {
    var $modal = $dom.find('#template-user-modal');
    $modal.find('.alert').remove();

    var $alert = $('<div></div>');
    $alert
      .addClass('alert alert-warning')
      .attr('role', 'alert')
      .text(err.message)
    ;

    $modal.find('.modal-header').prepend($alert);

    resolve();
  });
}


function refreshUserList(param) {
  return new Promise(function(resolve, reject) {
    Promise.resolve(param)
      .then(getUsers)
      .then(getSetting)
      .then(setUserTableRow)
      .then(resolve)
      .catch(reject)
    ;
  });
}

function styleSetup(param) {
  return new Promise(function(resolve, reject) {
    $dom.find('[type="checkbox"]').bootstrapSwitch();
    resolve(param);
  });
}

function validateFile(param) {
  return new Promise(function(resolve, reject) {
    if (!window.FileReader) return reject('this browser not support File Api');
    if (!param.file || param.file.length == 0) return reject('no file');
    if (param.file.size == 0) return reject('zero data file');
    resolve(param);
  });
}

function setBulkUserTable(param) {
  return new Promise(function(resolve, reject) {

    var table = $('<table></table>');

    table
      .addClass('table table-striped table-bordered table-hover')
      .append(
        $('<thead></thead>')
          .append(
            $('<tr></tr>')
              .append($('<th></th>').text('seq'))
              .append($('<th></th>').text(i18n.get('dsp.control.username')))
              .append($('<th></th>').text(i18n.get('dsp.control.userFullName')))
              .append($('<th></th>').text(i18n.get('dsp.control.role.title')))
              .append($('<th></th>').text(i18n.get('dsp.control.email')))
              .append($('<th></th>').text(i18n.get('dsp.control.phone')))
          )
      )
      .append(
        $('<tbody></tbody>')
      )
    ;

    $dom.find('#template-user-bulk-modal .edit-zone')
      .empty()
      .append(table);

    resolve(param);
  });

}

function renderBulkList(param) {
  return new Promise(function(resolve, reject) {

    var $tbody = $dom.find('#template-user-bulk-modal .edit-zone table').find('tbody');
    $tbody
      .empty();

    var reader = new FileReader();

    reader.onload = (function(theFile) {
      return function(e) {
        var csv = e.target.result.split('\n');
        csv.shift();  // remove first(header) row

        $.each(csv, function(index, val) {
          var rowArray = val.replace('"', '').split(",");

          if (rowArray.length !== 6) return true; // continue

          var row = $('<tr></tr>');
          row
            .attr('data-json', JSON.stringify(rowArray))
            .append($('<td></td>').text(index))
            .append($('<td></td>').text(rowArray[0]))
            .append($('<td></td>').text(rowArray[1]))
            .append($('<td></td>').text(rowArray[3]))
            .append($('<td></td>').text(rowArray[4]))
            .append($('<td></td>').text(rowArray[5]))
          ;

          $tbody
            .append(row);
        });

        resolve(param);
      }
    })(param.file);

    reader.readAsText(param.file);

  });
}

