var $ = require('jquery');
var config = (function (props) {
                        var self = {};
                        var _getByPropPath = function (o, s) {
                            // http://stackoverflow.com/a/6491621
                            s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
                            s = s.replace(/^\./, '');           // strip a leading dot
                            var a = s.split('.');
                            for (var i = 0, n = a.length; i < n; ++i) {
                                var k = a[i];
                                if (k in o) {
                                    o = o[k];
                                } else {
                                    return;
                                }
                            }
                            return o;
                        };

                        self.get = function (a) {
                            return _getByPropPath(props, a);
                        };

                        return self;
                    })({"Client":{"fadeInterval":500,"dataTables":{"fixedHeader":{"header":true,"footer":false},"ordering":true,"paging":true,"processing":true,"scrollX":true,"scrollY":true,"language":{"paginate":{"first":"先頭へ","previous":"前へ","next":"次へ","last":"最後へ"},"aria":{"paginate":{"first":"先頭へ","previous":"前へ","next":"次へ","last":"最後へ"}},"decimal":",","thousands":",","emptyTable":"対象データなし","lengthMenu":"<select><option value='10'>10件</option><option value='50'>50件</option><option value='100'>100件</option><option value='200'>200件</option><option value='-1'>全て</option></select>表示","loadingRecords":"Now Loading...","processing":"Now Processing...","search":"全文検索：","searchPlaceholder":"検索ワード","zeroRecords":"対象データなし","info":"_PAGE_ / _PAGES_ ページを表示中","infoEmpty":"対象データなし","infoFiltered":"(全_MAX_件からフィルタリング中)"}}}});;
var validator = require('validator');
var error = require('../error');
var notice = require('../notice');
var confirm = require('../confirm');
var i18n = require('../i18n');
var $dom = $('#template-setting');

module.exports = {
  show: show,
  hide: hide
}

function show() {
  return new Promise(function(resolve, reject) {
    Promise.resolve()
      .then(styleSetup)
      .then(getData)
      .then(setForm)
      .then(eventBind)
      .then(function() {
        $dom.fadeIn(config.get('Client.fadeInterval')).promise().done(resolve);
      })
      .catch(reject)
    ;
  });
}

function hide() {
  return new Promise(function(resolve, reject) {
    Promise.resolve()
      .then(eventUnBind)
      .then(function() {
        $dom.fadeOut(config.get('Client.fadeInterval')).promise().done(resolve);
      })
      .catch(reject)
    ;
  });
}

function eventBind() {
  return new Promise(function(resolve, reject) {
    $dom.on('change', 'input', onChange);
    $dom.on('switchChange.bootstrapSwitch', onChange);

    resolve();
  });
}

function eventUnBind() {
  return new Promise(function(resolve, reject) {
    $dom.off('change', 'input');
    $dom.off('switchChange.bootstrapSwitch');
    resolve();
  });
}

function onChange(e) {

  var $target = $(e.target);
  var type = $target.attr('type');
  if (type && type == 'number') {
    if (!validator.isInt($target.val(), {min:0, max:999})) {
      notice.warning(i18n.get('validate.invalid.general'));
      return false;
    }
  }

  var data = $dom.find('form').serialize();
  Promise.resolve(data)
    .then(putData)
    .then(function() {
      return notice.success(i18n.get('dsp.success'));
    })
    .catch(function(err) {
      if (err.message)
        notice.error(err.message);
      else {
        notice.error(err);
      }
    })
  ;

}

function styleSetup() {
  return new Promise(function(resolve, reject) {
    $dom.find('.collapse').collapse();
    $dom.find('[type="checkbox"]').bootstrapSwitch();
    resolve();
  });
}

function setForm(json) {
  return new Promise(function(resolve, reject) {

    if (!json || !json instanceof Array || !json[0] || !json[0].password || !json[0].system)
      reject(new error.UnexpectedDataException('unexpected json->' + json));

    $dom.find('#input-id').val(json[0]['_id']);

    Object.keys(json[0].system).forEach(function(key) {
      var value = json[0].system[key];

      if (typeof value == 'boolean') {
        if (value) {
          $dom.find('#input-' + key).bootstrapSwitch('state', true, true);
        } else {
          $dom.find('#input-' + key).bootstrapSwitch('state', false, false);
        }
      } else {
        $dom.find('#input-' + key).val(value);
      }

    });

    Object.keys(json[0].password).forEach(function(key) {
      var value = json[0].password[key];

      if (typeof value == 'boolean') {
        //$dom.find('#input-' + key).prop('checked', true);
        if (value) {
          $dom.find('#input-' + key).bootstrapSwitch('state', true, true);
        } else {
          $dom.find('#input-' + key).bootstrapSwitch('state', false, false);
        }
      } else {
        $dom.find('#input-' + key).val(value);
      }

    });

    resolve();
  });

}

function getData() {
  return new Promise(function(resolve, reject) {
    $.ajax({
      type: 'get',
      url: '/local/api/setting',
      success: resolve,
      error: function(xhr) {
        if (xhr.status == 401) {
          throw new error.UnAuthorizedException();
        }
        reject(new error.AjaxException(xhr));
      }
    });
  });
}

function putData(data) {
  return new Promise(function(resolve, reject) {
    $.ajax({
      type: 'put',
      url: '/local/api/setting',
      data: data,
      success: resolve,
      error: function(xhr) {
        if (xhr.status == 401) {
          throw new error.UnAuthorizedException();
        }
        if (xhr.status == 403) {
          throw new error.ForbiddenException();
        }
        if (xhr.status == 400) {
          reject(new error.AjaxValidateException(xhr));
        }
        reject(new error.AjaxException(xhr));
      }
    });
  });
}
