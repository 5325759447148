var $ = require('jquery');
var config = (function (props) {
                        var self = {};
                        var _getByPropPath = function (o, s) {
                            // http://stackoverflow.com/a/6491621
                            s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
                            s = s.replace(/^\./, '');           // strip a leading dot
                            var a = s.split('.');
                            for (var i = 0, n = a.length; i < n; ++i) {
                                var k = a[i];
                                if (k in o) {
                                    o = o[k];
                                } else {
                                    return;
                                }
                            }
                            return o;
                        };

                        self.get = function (a) {
                            return _getByPropPath(props, a);
                        };

                        return self;
                    })({"Client":{"fadeInterval":500,"dataTables":{"fixedHeader":{"header":true,"footer":false},"ordering":true,"paging":true,"processing":true,"scrollX":true,"scrollY":true,"language":{"paginate":{"first":"先頭へ","previous":"前へ","next":"次へ","last":"最後へ"},"aria":{"paginate":{"first":"先頭へ","previous":"前へ","next":"次へ","last":"最後へ"}},"decimal":",","thousands":",","emptyTable":"対象データなし","lengthMenu":"<select><option value='10'>10件</option><option value='50'>50件</option><option value='100'>100件</option><option value='200'>200件</option><option value='-1'>全て</option></select>表示","loadingRecords":"Now Loading...","processing":"Now Processing...","search":"全文検索：","searchPlaceholder":"検索ワード","zeroRecords":"対象データなし","info":"_PAGE_ / _PAGES_ ページを表示中","infoEmpty":"対象データなし","infoFiltered":"(全_MAX_件からフィルタリング中)"}}}});;
var typeCheck = require('type-check').typeCheck
var notice = require('./notice');
var i18n = require('./i18n');

// =======================
// Custom Exception
// =======================
var AjaxValidateException = function(xhr) {
  this.name = 'ajaxvalidateexception';
  this.xhr = xhr;

  if (xhr && xhr.responseJSON) {
    var json = xhr.responseJSON;
    this.json = json;

    if (json.errmsg) this.message = json.errmsg;
    if (json.message) this.message = json.message;
  }
}
AjaxValidateException.prototype = new Error();
AjaxValidateException.prototype.constructor = AjaxValidateException;

var AjaxException = function(xhr) {
  this.name = 'ajaxexception';
  this.xhr = xhr;

  if (xhr && xhr.responseJSON) {
    var json = xhr.responseJSON;
    this.json = json;

    if (json.errmsg) this.message = json.errmsg;
    if (json.message) this.message = json.message;
    return;
  }

  if (xhr.state && (xhr.state() == 'rejected')) {
    this.message = 'failed connect server'
    return;
  }

}
AjaxException.prototype = new Error();
AjaxException.prototype.constructor = AjaxException;

var UnexpectedDataException = function(message) {
  this.name = 'unexpectedDataException';
  this.message = message;
}
UnexpectedDataException.prototype = new Error();
UnexpectedDataException.prototype.constructor = UnexpectedDataException;

var ForbiddenException = function(message) {
  this.name = 'forbiddenException';
  this.message = i18n.get('dsp.csrf');
}
ForbiddenException.prototype = new Error();
ForbiddenException.prototype.constructor = ForbiddenException;

var UnAuthorizedException = function(message) {
  this.name = 'unauthorizederror';
  this.message = message;
}
UnAuthorizedException.prototype = new Error();
UnAuthorizedException.prototype.constructor = UnAuthorizedException;

// =======================
// Error handling
// =======================
function show(err) {
  return new Promise(function(resolve, reject) {
    if (err instanceof AjaxValidateException) {
      return notice.warning(err.message);
    };
    if (err instanceof AjaxException) {
      return notice.error(err.message);
    };
    if (err instanceof ForbiddenException) {
      return notice.error(err.message);
    };
    if (err instanceof UnexpectedDataException) {
      return notice.error(err.message);
    };
    if (err instanceof Error){
      return notice.error(err.message);
    };
    if (typeCheck('String', err)) {
      return notice.error(err);
    }
    if (typeCheck('Array', err)) {
      return notice.error(err.join(','));
    }

  });
}

module.exports = {
  UnAuthorizedException: UnAuthorizedException,
  AjaxValidateException: AjaxValidateException,
  AjaxException: AjaxException,
  UnexpectedDataException: UnexpectedDataException,
  ForbiddenException: ForbiddenException,
  show: show
}
