var $ = require('jquery');
var config = (function (props) {
                        var self = {};
                        var _getByPropPath = function (o, s) {
                            // http://stackoverflow.com/a/6491621
                            s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
                            s = s.replace(/^\./, '');           // strip a leading dot
                            var a = s.split('.');
                            for (var i = 0, n = a.length; i < n; ++i) {
                                var k = a[i];
                                if (k in o) {
                                    o = o[k];
                                } else {
                                    return;
                                }
                            }
                            return o;
                        };

                        self.get = function (a) {
                            return _getByPropPath(props, a);
                        };

                        return self;
                    })({"Client":{"fadeInterval":500,"dataTables":{"fixedHeader":{"header":true,"footer":false},"ordering":true,"paging":true,"processing":true,"scrollX":true,"scrollY":true,"language":{"paginate":{"first":"先頭へ","previous":"前へ","next":"次へ","last":"最後へ"},"aria":{"paginate":{"first":"先頭へ","previous":"前へ","next":"次へ","last":"最後へ"}},"decimal":",","thousands":",","emptyTable":"対象データなし","lengthMenu":"<select><option value='10'>10件</option><option value='50'>50件</option><option value='100'>100件</option><option value='200'>200件</option><option value='-1'>全て</option></select>表示","loadingRecords":"Now Loading...","processing":"Now Processing...","search":"全文検索：","searchPlaceholder":"検索ワード","zeroRecords":"対象データなし","info":"_PAGE_ / _PAGES_ ページを表示中","infoEmpty":"対象データなし","infoFiltered":"(全_MAX_件からフィルタリング中)"}}}});;
var validator = require('validator');
var error = require('../error');
var notice = require('../notice');
var confirm = require('../confirm');
var i18n = require('../i18n');
var csvmaker = require('../csvmaker');
var $dom = $('#template-user-bulk-modal');

module.exports = {
  show: show,
  hide: hide
}

function show() {
  return new Promise(function(resolve, reject) {
    Promise.resolve({})
      .then(init)
      .then(getSetting)
      .then(eventBind)
      .catch(reject)
    ;
  });
}

function hide() {
  return new Promise(function(resolve, reject) {
    Promise.resolve({})
      .then(eventUnBind)
      .catch(reject)
    ;
  });
}

function init(param) {
  return new Promise(function(resolve, reject) {
    $dom.find('.upload-control').val('');
    $dom.find('.upload-zone').css('border', 'none');
    $dom.find('.upload-zone').show();
    $dom.find('.edit-zone').hide();
    $dom.find('.btn-execute-bulk').hide();
    $dom.modal({});
    resolve(param);
  });
}

function eventBind(param) {
  return new Promise(function(resolve, reject) {
    $dom.on('show.bs.modal', function(e) {
    });

    $dom.on('hide.bs.modal', function(e) {
      hide();
    });

    // drag over
    $dom.on('dragover', '.upload-zone', function(e) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    });

    // drag enter
    $dom.on('dragenter', '.upload-zone', function(e) {
      e.preventDefault();
      e.stopPropagation();
      $dom.find('.upload-zone').css('border', 'dotted');
      return false;
    });

    // drag enter
    $dom.on('dragleave', '.upload-zone', function(e) {
      e.preventDefault();
      e.stopPropagation();
      $dom.find('.upload-zone').css('border', 'none');
      return false;
    });

    // drop
    $dom.on('drop', '.upload-zone', function(e) {
      e.preventDefault();
      e.stopPropagation();

      $dom.find('.upload-zone').css('border', 'none');

      // if drag & drop from inner browser
      if (!e.originalEvent.dataTransfer || !e.originalEvent.dataTransfer.items) {
        return false;
      }

      var item = e.originalEvent.dataTransfer.items[0]; // support single file
      var entry = (item.getAsEntry) ? item.getAsEntry() : item.webkitGetAsEntry();

      if (!validator.matches(entry.name, '.+\.csv', 'i')) {
        notice.warning(i18n.get('validate.invalidFile'));
        return false;
      }

      if (entry == null) return false;
      if (entry.isDirectory) return false;

      entry.file(function(theFile) {
        var param = {
          file: theFile
        };
        Promise.resolve(param)
          .then(validateFile)
          .then(getRole)
          .then(getSetting)
          .then(setBulkUserTable)
          .then(renderBulkList)
          .then(showBulkList)
          .catch(error.show)
        ;
      });

    });


    // bulk update file select button click event
    $dom.on('click', '.file-select', function(e) {
      e.preventDefault();
      e.stopPropagation();

      var $target = $(e.target);
      $target.siblings('input.upload-control').trigger('click');

    });

    $dom.on('change', '.upload-control', function(e) {
      e.preventDefault();
      e.stopPropagation();

      var files = $(this)[0].files;
      var param = {
        file: files[0]  // support one file only
      }
      Promise.resolve(param)
        .then(validateFile)
        .then(getRole)
        .then(getSetting)
        .then(setBulkUserTable)
        .then(renderBulkList)
        .then(showBulkList)
        .catch(error.show)
      ;

    });

    $dom.on('click', '.btn-csv-download', function(e) {
      var layout = [];
      var header = [];
      header.push(i18n.get('dsp.control.username'));
      header.push(i18n.get('dsp.control.userFullName'));
      header.push(i18n.get('dsp.control.password'));
      header.push(i18n.get('dsp.control.role.title'));

      if (param.setting.system.enableProfileEmail)
        header.push(i18n.get('dsp.control.email'));
      if (param.setting.system.enableProfilePhone)
        header.push(i18n.get('dsp.control.phone'));
      if (param.setting.system.enableExtentionKey1)
        header.push((param.setting.system.extentionKey1Name) ? param.setting.system.extentionKey1Name : i18n.get('dsp.control.setting.system.extentionKey1Name.init'));
      if (param.setting.system.enableExtentionKey2)
        header.push((param.setting.system.extentionKey2Name) ? param.setting.system.extentionKey2Name : i18n.get('dsp.control.setting.system.extentionKey2Name.init'));
      if (param.setting.system.enableExtentionKey3)
        header.push((param.setting.system.extentionKey3Name) ? param.setting.system.extentionKey3Name : i18n.get('dsp.control.setting.system.extentionKey3Name.init'));

      layout.push(header);
      csvmaker.download(layout, 'sample.csv');
    });

    $dom.on('click', '.btn-execute-bulk', function(e) {
      var targets = $dom.find('.edit-zone').find('tbody tr:not(.warning):not(.success)');
      if (!targets || targets.length == 0) return false;

      var inputs = [];
      $.each(targets, function(index, target) {
        inputs.push({
          seq: $(target).attr('data-seq'),
          value: JSON.parse($(target).attr('data-json'))
        });
      });

      var param = {
        inputs: inputs
      };

      Promise.resolve(param)
        .then(executeBulkInsert)
        .catch(error.show)
    });

    // list row delete button click
    $dom.on('click', '.btn-delete-row', function(e) {
      $(e.target).closest('tr').remove();
    });

    resolve(param);
  });
}

function eventUnBind() {
  return new Promise(function(resolve, reject) {
    $dom.off('show.bs.modal');
    $dom.off('hide.bs.modal');
    $dom.off('click', '.file-select');
    $dom.off('change', '.upload-control');
    $dom.off('dragover', '.upload-zone');
    $dom.off('dragenter', '.upload-zone');
    $dom.off('dragleave', '.upload-zone');
    $dom.off('drop', '.upload-zone');
    $dom.off('click', '.btn-csv-download');
    $dom.off('click', '.btn-execute-bulk');
    $dom.off('click', '.btn-delete-row');

    resolve();
  });
}

function validateFile(param) {
  return new Promise(function(resolve, reject) {
    if (!window.FileReader) return reject(i18n.get('validate.dropUnsupported'));
    if (!param.file || param.file.length == 0) return reject(i18n.get('validate.fileNotFound'));
    if (param.file.size == 0) return reject(i18n.get('validate.emptyFile'));
    resolve(param);
  });
}

function getRole(param) {
  return new Promise(function(resolve, reject) {
    $.ajax({
      type: 'get',
      url: '/local/api/roles',
      success: function(json) {
        param.roles = json;
        resolve(param);
      },
      error: function(xhr) {
        if (xhr.status == 401) {
          throw new error.UnAuthorizedException();
        }
        reject(new error.AjaxException(xhr));
      }
    });
  });
}

function setBulkUserTable(param) {
  return new Promise(function(resolve, reject) {

    var table = $('<table></table>');

    var tr = $('<tr></tr>');
    tr.append($('<th></th>').text(''))
    tr.append($('<th></th>').text('seq'))
    tr.append($('<th></th>').text(i18n.get('dsp.control.username')))
    tr.append($('<th></th>').text(i18n.get('dsp.control.userFullName')))
    tr.append($('<th></th>').text(i18n.get('dsp.control.password')))
    tr.append($('<th></th>').text(i18n.get('dsp.control.role.title')))

    if (param.setting.system.enableProfileEmail)
      tr.append($('<th></th>').text(i18n.get('dsp.control.email')))
    if (param.setting.system.enableProfilePhone)
      tr.append($('<th></th>').text(i18n.get('dsp.control.phone')))
    if (param.setting.system.enableExtentionKey1)
      tr.append($('<th></th>').text((param.setting.system.extentionKey1Name) ? param.setting.system.extentionKey1Name : i18n.get('dsp.control.setting.system.extentionKey1Name.init')))
    if (param.setting.system.enableExtentionKey2)
      tr.append($('<th></th>').text((param.setting.system.extentionKey2Name) ? param.setting.system.extentionKey2Name : i18n.get('dsp.control.setting.system.extentionKey2Name.init')))
    if (param.setting.system.enableExtentionKey3)
      tr.append($('<th></th>').text((param.setting.system.extentionKey3Name) ? param.setting.system.extentionKey3Name : i18n.get('dsp.control.setting.system.extentionKey3Name.init')))
    tr.append($('<th></th>').text(i18n.get('dsp.control.status.label')))

    table
      .addClass('table table-striped table-bordered table-hover')
      .append(
        $('<thead></thead>')
          .append(tr)
      )
      .append(
        $('<tbody></tbody>')
      )
    ;

    $dom.find('.edit-zone')
      .empty()
      .append(table);

    resolve(param);
  });

}

function renderBulkList(param) {
  return new Promise(function(resolve, reject) {

    var $tbody = $dom.find('.edit-zone table').find('tbody').empty();

    var reader = new FileReader();

    reader.onload = (function(theFile) {
      return function(e) {
        var csv = e.target.result.split('\n');
        csv.shift();  // remove first(header) row
        if (csv.length == 0) return reject(i18n.get('validate.nodata'));

        $dom.find('.btn-execute-bulk').show();

        var promises = [];
        $.each(csv, function(index, val) {
          if (val.length == 0) return true; // continue

          var render_param = {
            csv: {
              index: index,
              val: val.replace('"', '').split(",")
            },
            roles: param.roles,
            setting: param.setting
          };

          promises.push(makeRow(render_param));

        });

        Promise.all(promises)
          .then(function(results) {
            results.map(function(result) {
              $tbody.append(result.row);
            });
          })
          .then(function() {
            resolve(param);
          })
          .catch(error.show)
        ;

      }
    })(param.file);

    reader.readAsText(param.file);

  });
}

function makeRow(param) {
  return new Promise(function(resolve, reject) {

    Promise.resolve(param)
      .then(validateCsvRow)
      .then(makeCsvRow)
      .then(resolve)
      .catch(function(param) {
        makeErrorRow(param)
          .then(resolve)
          .catch(reject)
        ;
      })
    ;
  });
}

function validateCsvRow(param) {
  return new Promise(function(resolve, reject) {
    var column_length = 4;  // default(username, fullName, password, role, )
    if (param.setting.system.enableProfileEmail) column_length++;
    if (param.setting.system.enableProfilePhone) column_length++;
    if (param.setting.system.enableExtentionKey1) column_length++;
    if (param.setting.system.enableExtentionKey2) column_length++;
    if (param.setting.system.enableExtentionKey3) column_length++;

    if (param.csv.val.length !== column_length) {
      param.csv.errormessage = i18n.get('validate.userbulk.invalidColumnCount');
      return reject(param);
    }

    // if role not setted -> skip role validation
    if (param.csv.val[3].trim() == "") return resolve(param);

    param.csv.role_ids = roleNamesToRoleIds(param.csv.val[3], param.roles);
    if (param.csv.role_ids.length == 0 || param.csv.role_ids.length !== param.csv.val[3].split('-').length) {
      param.csv.errormessage = i18n.get('validate.userbulk.invalidRole');
      return reject(param);
    }

    resolve(param);
  });
}

function makeCsvRow(param) {
  return new Promise(function(resolve, reject) {

    var row = $('<tr></tr>');
    row
      .attr('data-seq', param.csv.index)
      .attr('data-json', JSON.stringify(param.csv))
      .append($('<td></td>').append(
        $('<button></button>')
          .addClass('btn btn-sm btn-primary btn-delete-row')
          .append(
            $('<i></i>')
              .addClass('fa fa-close')
          )
      ))
    ;

    row.append($('<td></td>').text(param.csv.index + 1));
    row.append($('<td></td>').text(param.csv.val[0])); // username
    row.append($('<td></td>').text(param.csv.val[1])); // fullName
    row.append($('<td></td>').text('*********')); // password
    row.append($('<td></td>').text(param.csv.val[3].split('-').join(','))); // role

    // extended profile
    var column_count = 4;
    if (param.setting.system.enableProfileEmail)
      row.append($('<td></td>').text(param.csv.val[column_count++])); // email
    if (param.setting.system.enableProfilePhone)
      row.append($('<td></td>').text(param.csv.val[column_count++])); // phone
    if (param.setting.system.enableExtentionKey1)
      row.append($('<td></td>').text(param.csv.val[column_count++])); // extentionKey1
    if (param.setting.system.enableExtentionKey2)
      row.append($('<td></td>').text(param.csv.val[column_count++])); // extentionKey2
    if (param.setting.system.enableExtentionKey3)
      row.append($('<td></td>').text(param.csv.val[column_count++])); // extentionKey3

    row.append($('<td></td>').text(i18n.get('dsp.control.status.notyet'))); // status

    param.row = row;
    resolve(param);

  });
}

function makeErrorRow(param) {
  return new Promise(function(resolve, reject) {
    var row = $('<tr></tr>');
    row
      .addClass('warning')
      .append($('<td></td>').append(
        $('<button></button>')
          .addClass('btn btn-sm btn-primary btn-delete-row')
          .append(
            $('<i></i>')
              .addClass('fa fa-close')
          )
      ))
      .append($('<td></td>').text(param.csv.index + 1))
      .append($('<td></td>').text((param.csv.val[0]) ? param.csv.val[0] : '')) // username
      .append($('<td></td>').text((param.csv.val[1]) ? param.csv.val[1] : '')) // fullName
      .append($('<td></td>').text((param.csv.val[2]) ? '*********' : '')) // password
      .append($('<td></td>').text((param.csv.val[3]) ? param.csv.val[3].split('-').join(',') : '')) // role
    ;

    // extended profile
    var column_count = 4;
    if (param.setting.system.enableProfileEmail) {
      column_count++;
      row.append($('<td></td>').text((param.csv.val[column_count]) ? param.csv.val[column_count] : '')); // email
    }
    if (param.setting.system.enableProfilePhone) {
      column_count++;
      row.append($('<td></td>').text((param.csv.val[column_count]) ? param.csv.val[column_count] : '')); // email
    }
    if (param.setting.system.enableExtentionKey1) {
      column_count++;
      row.append($('<td></td>').text((param.csv.val[column_count]) ? param.csv.val[column_count] : '')); // email
    }
    if (param.setting.system.enableExtentionKey2) {
      column_count++;
      row.append($('<td></td>').text((param.csv.val[column_count]) ? param.csv.val[column_count] : '')); // email
    }
    if (param.setting.system.enableExtentionKey3) {
      column_count++;
      row.append($('<td></td>').text((param.csv.val[column_count]) ? param.csv.val[column_count] : '')); // email
    }
    row.append($('<td></td>').text(param.csv.errormessage)); // status

    param.row = row;
    resolve(param);
  });
}



function roleNamesToRoleIds(rolenames, roles) {
  if (!rolenames || !roles || roles.length == 0) return [];
  var names = rolenames.split('-');
  var ids = [];

  $.each(names, function(index, name) {
    $.each(roles, function(index, role) {
      if (name == role.name) ids.push(role._id);
    });
  });

  return ids;
}


function showBulkList(param) {
  return new Promise(function(resolve, reject) {
    $dom.fadeIn(config.get('Client.fadeInterval')).promise().done(resolve);

    $dom.find('.upload-zone').fadeOut().promise().done(function() {
      $dom.find('.edit-zone').fadeIn().promise().done(function() {
        resolve(param);
      });
    });
  });
}

function executeBulkInsert(param) {
  return new Promise(function(resolve, reject) {

    Promise.resolve(param)
      .then(getSetting)
      .then(editParam)
      .then(bulkInsert)
      .catch(reject)
    ;
  });
}

function editParam(param) {
  return new Promise(function(resolve, reject) {
    param.inputEdited = [];

    $.each(param.inputs, function(index, input) {
      var json = {
        seq: input.seq,
        username: input.value.val[0],
        fullName: input.value.val[1],
        password: input.value.val[2],
        roles: input.value.role_ids
      };

      // extended profile
      var column_count = 4;
      if (param.setting.system.enableProfileEmail)
        json.email = input.value.val[column_count++];
      if (param.setting.system.enableProfilePhone)
        json.phone = input.value.val[column_count++];
      if (param.setting.system.enableExtentionKey1)
        json.extentionKey1 = input.value.val[column_count++];
      if (param.setting.system.enableExtentionKey2)
        json.extentionKey2 = input.value.val[column_count++];
      if (param.setting.system.enableExtentionKey3)
        json.extentionKey3 = input.value.val[column_count++];

      param.inputEdited.push(json);
    });
    resolve(param);
  });
}

function bulkInsert(param) {
  return new Promise(function(resolve, reject) {

    var promises = [];
    $.each(param.inputEdited, function(index, input) {
      promises.push(doAjaxPost(input));
    });

    Promise.all(promises)
      .then(function(results) {
        results.map(function(result) {
          var $target = $dom.find('.edit-zone').find('tbody tr[data-seq='+ result.seq +']');
          $target.find('td:last').text(result.ajaxResult.message);
          if(result.ajaxResult.success) {
            $target.addClass('success');
          } else {
            $target.addClass('warning');
          }
        });
      })
      .then(function() {
        resolve(param);
      })
      .catch(error.show)
    ;
  });
}

function doAjaxPost(param) {
  return new Promise(function(resolve, reject) {
    var data = param;

    $.ajax({
      type: 'post',
      url: '/local/api/users',
      data: data,
      success: function(result) {
        data.ajaxResult = result;
        data.ajaxResult.success = true;
        resolve(data);
      },
      error: function(xhr) {
        if (xhr.status == 401) {
          throw new error.UnAuthorizedException();
        }
        if (xhr.status == 403) {
          throw new error.ForbiddenException();
        }
        if (xhr.status == 500) {
          return reject(new error.AjaxException(xhr));
        }

        data.ajaxResult = xhr.responseJSON;
        data.ajaxResult.success = false;
        return resolve(data);
      }
    });

  });
}

function getSetting(param) {
  return new Promise(function(resolve, reject) {
    $.ajax({
      type: 'get',
      url: '/local/api/setting',
      success: function(json) {
        if (json && json instanceof Array) {
          param.setting = json[0];
        } else {
          param.setting = undefined;
        }
        resolve(param);
      },
      error: function(xhr) {
        if (xhr.status == 401) {
          throw new error.UnAuthorizedException();
        }
        reject(new error.AjaxException(xhr));
      }
    });
  });
}

